import React, { ReactElement, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, InputAdornment, makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";
import { makeValidate } from "mui-rff";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCommonValidation } from "../utils/validators";
import { phoneParser } from "../utils/parsers";
import emailIcon from "../assets/icons/icon-blue-email.svg";
import PhoneInput from "../form/PhoneInput";
import useNavigation from "../utils/navigation";
import useUpdateWindowHeight from "../utils/window";
import { getPasswordResetCode } from "../api/vendor-core/v1";
import { ResetPasswordContactContext } from "../contexts/ResetPasswordContactContext";
import EmailInput from "../form/EmailInput";

const useStyles = makeStyles({
  input: {
    margin: "4px 0",
    minHeight: "50px",
  },
  icon: {
    color: "#309CD0",
  },
});

export default function ContactForm(): ReactElement {
  // quick fix for disabled phone
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const { email: emailValidation } = useCommonValidation();
  const { navigateTo } = useNavigation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setResetPasswordData } = React.useContext(
    ResetPasswordContactContext
  );
  const locale = window.location.href.split("?")[0].split("/").pop();
  const callback_url = `https://dashboard.dcmarket.mk/${locale}/reset-success`;
  useUpdateWindowHeight();

  const pathVar = window.location.href.split("/");
  pathVar.pop(); // locale
  const resetOption = pathVar.pop();
  // eslint-disable-next-line consistent-return
  const handleSubmitForm = async ({ email, phoneNumber, phonePrefix }: any) => {
    if (isPhoneValid) {
      try {
        const phone_number = phoneNumber && `${phonePrefix}${phoneNumber}`;
        const response = await getPasswordResetCode({
          email,
          phone_number,
          callback_url,
        });
        if (response.ok) {
          setResetPasswordData({ email, phone_number });
          navigateTo("forgot-password/enter-code");
        }
      } catch (e: any) {
        if (e?.response?.obj.detail) {
          return {
            email: t(`serverValidation.${e?.response?.obj?.detail}`),
          };
        }
      }
    }
  };

  const fieldName = resetOption === "email" ? "email" : "phoneNumber";

  const schema = useMemo(() => {
    return Yup.object().shape({
      [fieldName]:
        resetOption === "email"
          ? emailValidation.required("Е-маилот е задолжителен")
          : Yup.string()
              .required("Телефонскиот број е задолжителен")
              .nullable(),
    });
  }, [emailValidation, fieldName, resetOption]);
  const validate = makeValidate(schema);

  const handleValidPhone = (isValid: boolean) => {
    setIsPhoneValid(isValid);
  };

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmitForm}
      initialValues={{ phonePrefix: "389" }}
      render={({ handleSubmit }): ReactElement => {
        return (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              style={{
                flexFlow: "column",
                alignItems: "center",
                marginLeft: "40px",
                marginRight: "40px",
                minWidth: "300px",
              }}
            >
              <div style={{ margin: "20px 0", width: "100%" }}>
                <h3>{t("forgotPasswordForm.enterContact.title")}</h3>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label style={{ color: "#7A7C83" }}>
                  {fieldName === "email"
                    ? t("forgotPasswordForm.enterContact.subTitle")
                    : t("forgotPasswordForm.enterContact.subTitlePhone")}
                </label>
              </div>
              {resetOption === "email" ? (
                <EmailInput
                  name="email"
                  className={classes.input}
                  placeholder={t("forgotPasswordForm.email")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={emailIcon} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <PhoneInput
                  name="phone"
                  placeholder={t("forgotPasswordForm.phone")}
                  handleValidPhone={handleValidPhone}
                  // fieldProps={{ parse: phoneParser }}
                />
              )}
              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: "20px", width: "100%" }}
                onClick={handleSubmit}
              >
                {t("forgotPasswordForm.buttons.nextStep")}
              </Button>
              <span
                style={{
                  marginTop: "20px",
                  cursor: "pointer",
                  color: "#7A7C83",
                  maxWidth: "300px",
                  alignSelf: "start",
                }}
                role="button"
                onClick={() => navigateTo("login")}
                tabIndex={0}
              >
                {t("backToLogin")}
              </span>
            </Box>
          </form>
        );
      }}
    />
  );
}
